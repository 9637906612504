<template>
  <svg
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.67525 7.41199C5.61231 5.63788 8.23763 4.46827 9.55122 3.90318C13.3018 2.28972 14.0811 2.00944 14.5891 2.00009C14.7008 1.99815 14.9506 2.02679 15.1124 2.16259C15.249 2.27725 15.2866 2.43215 15.3046 2.54086C15.3226 2.64958 15.345 2.89723 15.3272 3.09075C15.1239 5.29945 14.2445 10.6594 13.7971 13.1332C13.6078 14.1799 13.235 14.5309 12.8741 14.5652C12.0899 14.6399 11.4943 14.0292 10.7347 13.5142C9.54608 12.7083 8.87457 12.2066 7.72079 11.4202C6.3874 10.5114 7.25178 10.0119 8.01168 9.19563C8.21055 8.98199 11.6661 5.73118 11.733 5.43628C11.7413 5.3994 11.7491 5.26192 11.6701 5.18932C11.5912 5.11673 11.4746 5.14155 11.3905 5.1613C11.2713 5.18928 9.37242 6.48738 5.69391 9.0556C5.15492 9.4384 4.66672 9.62491 4.22932 9.61514C3.74711 9.60436 2.81954 9.33314 2.12998 9.10131C1.28421 8.81696 0.612012 8.66662 0.670545 8.18371C0.701033 7.93218 1.03594 7.67494 1.67525 7.41199Z"
    />
  </svg>
</template>
