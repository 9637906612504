import type { Game, GamesList } from "~/types/games";
import { getRefresh } from "~/utils/tokens/getRefresh";

export const useGamesStore = defineStore("gamesStore", () => {
  /**
   * Данные по играм.
   */
  const games = ref<Game[]>([]);
  const count = ref(0);
  const userStore = useUserStore();
  /**
   * Общее кол-во игр.
   */
  const total = ref(0);

  /**
   * Индикатор загрузки следующей части игр.
   */
  const loading = ref(false);
  /**
   * Кол-во загружаемых игр в одном запросе.
   */
  const limit = 16;
  const baseURL = isClient()
    ? `${useRuntimeConfig().public.clientApiBase}api/`
    : `${useRuntimeConfig().public.serverApiBase}api/`;

  async function fetchGames(page: number) {
    try {
      const { data } = await useFetch<GamesList>(
        `/games/?limit=${limit}&offset=${page * limit}`,
        {
          baseURL,
          credentials: "include",
          headers: userStore.getAuthorizationHeader(),
        },
      );
      if (
        data.value &&
        "count" in data.value &&
        "next" in data.value &&
        "results" in data.value &&
        Array.isArray(data.value.results)
      ) {
        games.value = data.value.results;
        count.value = data.value.count;
        total.value = Math.ceil(data.value.count / limit);
      }
    } catch (e: any) {
      if (e.statusCode === 401) {
        const refresh = getRefresh() || "";
        await userStore.refreshTokens(refresh);
        await fetchGames(page);
      }
    }
  }

  return {
    games,
    count,
    total,
    loading,
    fetchGames,
  };
});
